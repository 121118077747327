import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
     /* {
        id: 'page-layouts',
        title: 'Horizontal Layouts',
        type: 'item',
        url: '/layout/horizontal',
        target: true,
        icon: 'feather icon-layout'
      }*/
    ]
  },
  
 
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'feather icon-file-text',
    children: [
     
      {
        id: 'administracion',
        title: 'Administracion',
        type: 'collapse',
        icon: 'feather icon-sliders',
        children: [
          /*{
            id: 'error',
            title: 'Error',
            type: 'item',
            url: '/maintenance/error',
            target: true,
            breadcrumbs: false
          },*/
          {
            id: 'Transmicion',
            title: 'Transmicion',
            type: 'item',
            url: '/adminitracion/Transmicion',
            /*target: true,
            breadcrumbs: false*/
          },
          {
            id: 'Noticias',
            title: 'Noticias',
            type: 'item',
            url: '/adminitracion/noticias',
            /*target: true,
            breadcrumbs: false*/
          }
        ]
      },
      {
        id: 'maintenance',
        title: 'Mantenimientos',
        type: 'collapse',
        icon: 'feather icon-sliders',
        children: [
        
          {
            id: 'Banner',
            title: 'Banner',
            type: 'item',
            url: '/maintenance/banner',
           
          },
         /* {
            id: 'Generos',
            title: 'Generos',
            type: 'item',
            url: '/maintenance/detalle-catalogo/1',
           
          },
          {
            id: 'Tipo persona',
            title: 'Tipo persona',
            type: 'item',
            url: '/maintenance/detalle-catalogo/3',
        
          },
          {
            id: 'Categoria clientes',
            title: 'Categoria clientes',
            type: 'item',
            url: '/maintenance/detalle-catalogo/4',
           
          },
          {
            id: 'Marcas',
            title: 'Marcas',
            type: 'item',
            url: '/maintenance/detalle-catalogo/5',
        
          },
          {
            id: 'Categoria Productos',
            title: 'Categoria Productos',
            type: 'item',
            url: '/maintenance/detalle-catalogo/6',
          },
          {
            id: 'Ciudades',
            title: 'Ciudades',
            type: 'item',
            url: '/maintenance/detalle-catalogo/7',
         
          }*/
        ]
      },
     /* {
        id: 'Ventas',
        title: 'Ventas',
        type: 'collapse',
        icon: 'feather icon-sliders',
        children: [
          
          {
            id: 'Inventario',
            title: 'Inventario',
            type: 'item',
            url: '/ventas/inventario'
            
          },
          {
            id: 'Ventas',
            title: 'Ventas',
            type: 'item',
            url: '/ventas/ventas'
          }
         
        ]
      }*/
    ]
  },
  
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
