import swal from 'sweetalert2';
import {
  Injectable
} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import jwt_decode from "jwt-decode";

declare var $;


@Injectable( 
  


)

export class SwallAlertGlobal {

  constructor( protected _sanitizer: DomSanitizer){

  }
  public urlGlobal;


  public swallAlert(message, tipo) {
    let type, title,timer;

    switch (tipo) {
      case 1:
        type = "success";
        title = "Exito!";
        timer=2000;
        break;
      case 2:
        type = "warning"
        title = "Advertencia"
        timer=300000;
        break;
      case 3:
        type = "error"
        title = "Error!"
        timer=300000;
        break;
    }

    swal.fire(title, message, type);
  }


  parseResponse(data){
    if(data!='')
        return JSON.parse(data)
    else
      return ''

  }


  setToken(data){
    sessionStorage.setItem("rios_token",data)
  }

  getToke(){
    return sessionStorage.getItem('rios_token');
  }


  url(value){
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }


  getDecodedAccessToken(): any {
    try{
        return jwt_decode(localStorage.getItem('rios_token'));
    }
    catch(Error){
        return null;
    }
  }

  getDecodedAccessTokenLogin(tocke): any {
    try{
        return jwt_decode(tocke);
    }
    catch(Error){
        return null;
    }
  }

  
}
