import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {    

    //public uriServer="http://localhost:8000/";
    //public uriServer="https://app-rios.innovationtc.com/";
	public uriServer=location.origin+"/";
    public ServerLocal_Security = this.uriServer + 'api/'
    public serverMantenimientos = this.uriServer + 'api/configuration/';
    public serverUtil = this.uriServer + 'api/';
    public serverAdministracion = this.uriServer + 'api/'
    public serverTalentoHumano=this.uriServer+'api/'
    public serverVentas=this.uriServer+'api/'
} 
   




 




