import {
  Component,
  OnInit
} from '@angular/core';
import {
  ServiceLogin
} from '../ServiceLogin/ServiceLogin'
import {
  SwallAlertGlobal
} from '../../../../ServiceGlobal/Alertas'
import {
  Router
} from '@angular/router';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss'],
  providers: [ServiceLogin, SwallAlertGlobal]
})
export class AuthSigninComponent implements OnInit {

  passwordType = "password";
  passwordShow = false;

  constructor(private ServiceLogin: ServiceLogin,
    private alerts: SwallAlertGlobal,
    private router: Router, ) {}

  dataLogin;
  ngOnInit() {
    sessionStorage.clear();
    this.dataLogin = {
      nombre: "",
      contrasena: ""
    }

    localStorage.clear()
  }



  message;
  login(datafront) {


    
    var body;

    this.message = ''

    body = JSON.stringify({
      username: datafront.nombre,
      password: datafront.contrasena

    })


    this.ServiceLogin.Send(body, this.ServiceLogin.uriLogin, 1)
      .subscribe(
        data => {
          if (data.status == 200) {
            //this.alerts.swallAlert(data.message, 1);


            let config = this.alerts.getDecodedAccessTokenLogin(data.XAUthToken);
            console.log(config)
            let iniciar = false;
            if (config != null) {
              for (let x of config.profilesAndResources) {
                if (x.profileId == 2) {
                  iniciar = true;
                  break;
                }
              }

            }

            if (iniciar) {
              //
              this.alerts.setToken(data.XAUthToken);

              setTimeout(() => {
                this.router.navigate(['/dashboard/analytics']);
              }, 1000);
            } else {
              this.message ="No tiene acceso a este modulo";
            }


            // 




          } else {
            this.message = data.message
            //this.alerts.swallAlert(data.message, 2);

          }

        },
        Error => {
          let data = this.alerts.parseResponse(Error._body)

          if (data.status == 401) {
            this.message = data.message
            // this.service.redirect();
          } else {
            this.message = 'Ocurrio un error';
          }
        }
      );
  }
  togglePassword() {
    if (this.passwordShow) {
      this.passwordShow = false;
      this.passwordType = "password";
    } else {
      this.passwordShow = true;
      this.passwordType = "text";
    }
  }
}
