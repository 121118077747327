import {
    Injectable
  } from '@angular/core';
  /*import {
   Http,
    Headers,
    RequestOptions,
    RequestMethodx
    HttpHeaders ,
    HttpClientModule
  } from '@angular/common/http';*/
  import { retry, catchError } from 'rxjs/operators';
  import { Observable, throwError } from 'rxjs';

  import {
    Http,
    Headers,
    RequestOptions,
    RequestMethod
  } from '@angular/http';
  

  
  import 'rxjs/add/operator/map';
  import 'rxjs/Rx';
  
  import {
    Configuration
  } from '../../../../ConfigSystems/constants';
  import {
    Router
  } from '@angular/router';
  
  
  @Injectable()
  export class ServiceLogin {
  
    
  
    constructor(//private http: Http,
      private _url: Configuration,
      private router: Router,
      private http: Http) {
  
    }
  
    private apiUrl = this._url.ServerLocal_Security;
    uriLogin="login"
  
   
  
  
  
  
  
  
  
    public Send(data, url, type) {
      let typeService;

      if (type == 1) {
        typeService = RequestMethod.Post;
      } else {
        typeService = RequestMethod.Put;
      }
  
      let headers = new Headers();
      headers.append('Content-Type', 'application/json; charset=utf-8');
     // headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
      let options = new RequestOptions({
        method: typeService,
        headers: headers
      });
     
        return this.http.post(this.apiUrl + url, data, options)
        .map(res => res.json());
      
      
     
    }
  
    public redirect() {
      this.router.navigate(['/401']);
    }
  
  
  }
  